@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .classic-input {
        @apply focus:border-blue-200 border border-gray-200 p-2 rounded-md focus:outline-none w-full;
    }
    .classic-card {
        @apply z-50 mx-10 lg:mx-auto p-5 bg-card_bg rounded-lg w-full lg:w-1/3 xl:w-1/5;
    }
    .classic-btn {
        @apply disabled:opacity-70 bg-classic_btn rounded-md p-2 text-white;
    }
    .classic_btn {
        @apply bg-blue-500 hover:bg-blue-400 text-white p-2 text-center cursor-pointer rounded-md;
    }
    .submit-btn {
        @apply bg-blue-400 text-white rounded-md hover:bg-blue-500 cursor-pointer text-center p-2;
    }
    .dialog-header {
        @apply p-4 text-center text-gray-700 rounded-t-lg
    }
    .orange-btn {
        @apply cursor-pointer bg-add_btn text-white px-4 text-sm font-bold py-2 rounded-md flex-center
    }
    .dialog {
        @apply mx-auto rounded-lg w-full lg:w-1/2 2xl:w-1/3
    }
    .backdrop {
        @apply fixed top-0 left-0 right-0 bottom-0 z-10 bg-backdrop
    }
    .delete-btn {
        @apply text-center cursor-pointer px-4 py-2 text-white bg-red-400 rounded-lg hover:bg-red-500
    }
    .cancel-btn{
        @apply text-gray-500 cursor-pointer px-4 py-2 text-center bg-gray-200 rounded-lg hover:bg-gray-300
    }

    .flex-center{
        @apply flex items-center justify-center;
    }
    .flex-start{
        @apply flex items-center justify-start;
    }
    .flex-end{
        @apply flex items-center justify-end;
    }
    .flex-between{
        @apply flex items-center justify-between;
    }

    .item-full{
        @apply flex-grow-0 flex-shrink-0 basis-full
    }
    .modal {
        top: 27vh;
        left: calc(50% - 200px);
        @apply fixed z-50 shadow-md
    }
    @media screen and (max-width: 725px){
        .modal {
            left: 0;
            right: 0;
        }
    }
    .loader {
        width: 20px;
        height: 20px;
        border: 2px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        animation: rotation 1s linear infinite;
        @apply mx-2 inline-block box-border;
    }
    @font-face {
        font-family: "Classic";
        src: url("../../public/fonts/Work_Sans/static/WorkSans-Regular.ttf");
    }
    ::-webkit-scrollbar {
        width: 7px;
        border-radius: 10px;
        scroll-behavior: smooth;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e9effc;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        @apply bg-gray-400
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        @apply bg-gray-500
    }
    select {
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        appearance:none;
    }
    *{
        font-family: Classic, 'sans-serif';
    }
    /**{*/
    /*    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
    /*}*/
}
